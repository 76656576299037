/* 
 * @Author: 曹俊杰 
 * @Date: 2024-03-14 15:18:08
 * @Module: 意向客户查重
 */
<template>

  <div>
    <el-alert title="注意：搜索时不要出现传媒，公司，文化，传播，互娱，娱乐，有限等后缀" :closable="false" type="warning">
    </el-alert>
    <div style="padding:20px;padding-top:10px;">
      <el-input placeholder="客户名称，手机号或微信号关键字，查询意向客户是否已被添加" v-model="search" style="width:40%;" size="mini" @keyup.enter.native="getClientSearch">
        <el-button slot="append" icon="el-icon-search" @click="getClientSearch"></el-button>
      </el-input>
      <el-table empty-text="手机号或微信号关键字，查询意向客户是否已被添加" border size="mini" style="width: 100%;margin-top:10px;" :data="list">
        <el-table-column label="公司名称" show-overflow-tooltip prop="companyName">
          <template slot-scope="scope">
            <span>{{ scope.row.companyName||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号码" show-overflow-tooltip prop="phone">
          <template slot-scope="scope">
            <span>{{ scope.row.phone||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信号码" show-overflow-tooltip prop="weixin">
          <template slot-scope="scope">
            <span>{{ scope.row.weixin||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" show-overflow-tooltip prop="note">
          <template slot-scope="scope">
            <span>{{ scope.row.note||"--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" show-overflow-tooltip prop="note" width="275">
          <template slot-scope="scope">
            <imageList :imageUrls="scope.row.image" v-if="scope.row.image" />
            <!-- <imagePreview :imageUrls="scope.row.image" style="margin-top:6px;" v-if="scope.row.image" /> -->
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="法务" show-overflow-tooltip prop="account.name">
          <template slot-scope="scope">
            <span>{{ scope.row.account.name||"--" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
 <script>
import imageList from "@/components/imageList"
export default {
  components: {
    imageList
  },
  data () {
    return {
      search: '',
      list: []
    };
  },
  mounted () { },
  methods: {
    async getClientSearch () {
      const { search: keyword } = this
      const { status, data } = await this.$api.getClientSearch({ keyword });
      console.log(data)
      if (status == 200) {
        this.list = data;
        if (data.length < 1) {
          this.$message({
            message: '没有查询结果，该客户未被录入系统',
            type: 'success'
          })
        }
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>